import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TemplateSearchComponent from "./TemplateSearchComponent.tsx"
import ProductForm from './ProductForm.tsx';
import {fetch_shop_product} from "../../../../../services/shops/products.js"

const Page: React.FC = () => {
    const { id } = useParams();
    const [product, setProduct] = useState({
        id: null,
        name: "",
        template: {id: 0, title: "", description: "", image: ""}
    })

    const onTemplateSelect = (template => {
        setProduct({
            ...product,
            template: template
        })
    })

    useEffect(() => {
        if (id !== undefined) {
            fetch_shop_product(id).then(res => {
                if (res.data) {
                    setProduct({
                        ...product,
                        id: res.data.id,
                        name: res.data.name, 
                        template: {
                            id: res.data.template_id,
                            title: res.data.name,
                            description: res.data.description,
                            image: res.data.img_picture
                        }
                    })
                }
            })
        }
    }, [])

    return (
        <div className="page-content products py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
            <div className="mb-4">
                <span className="fw-bold dashboard-title">
                    {id !== undefined ? "Edit Product" : "Add Product"}
                </span>
                <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
                    <Link className={"link"} to={"/admin/"}>
                        Dashboard
                    </Link>
                    <span>&gt;</span>
                    <Link className={"link"} to={"/admin/products/list"}>
                        Products
                    </Link>
                    <span>&gt;</span>
                    <span>{id !== undefined ? product.name : "New Product"}</span>
                </div>
            </div>

            <div className="inputs-container bg-white">
                {product.template.id === 0 ?
                    <div>
                        <h3>Select Your Product</h3>
                        <p className='mb-0'>Select your product form a list of predefined products</p>
                        <p className='mt-0 text-secondary'><small className='font-small'>if you cannot find a certain product, please contact the administrator in order to have it added by clicking <Link className='text-primary' to={"/admin/support"}>Here</Link></small></p>
                        <TemplateSearchComponent onTemplateSelect={onTemplateSelect} />
                    </div>
                    :
                    <div>
                        <h3>Selected Product</h3>
                        <ProductForm template={product.template}
                            dynamicFields={[]}
                            clearTemplate={() => {
                                setProduct({
                                    ...product,
                                    template: {id: 0, title: "", description: "", image: ""}
                                })
                            }} />
                    </div>
                }
            </div>
        </div>
    );
};

export default Page;
import React, { useEffect, useState } from 'react';
import { fetch_form_fields, save_form_data } from "../../../../../services/dynamic-form.ts"
import { useParams, useNavigate } from 'react-router-dom';
import DynamicInput from '../../../DynamicInput.jsx';

type ModelInfo = {
    title: string,
    save_url: string,
    index_url: string
}

type ProductFormProps = {
    clearTemplate: () => void,
    template: { id: number, title: string, description: string, image: string },
    dynamicFields: []
};

const ProductForm: React.FC<ProductFormProps> = ({ template, clearTemplate }) => {

    const { id = '' } = useParams();
    const [fields, setFields] = useState([])
    const [fieldValues, setFieldValues] = useState({})
    const [modelInfo, setModelInfo] = useState<ModelInfo>({ title: "", save_url: "", index_url: "" });
    const [errorState, setErrorState] = useState({})
    const navigate = useNavigate()

    const handleInputFieldChange = (fieldName: string, fieldValue: any) => {
        setFieldValues({
            ...fieldValues,
            [fieldName]: fieldValue,
        })
    }

    const handleSave = () => {
        save_form_data("/api/dash/shop/products/from-template", {
            ...fieldValues,
            product_id: id
        }).then((response) => {
            processValidationErrors(response, setErrorState)
            if (response.redirect_url !== undefined) {
                navigate(response.redirect_url)
            }
        })
    }

    useEffect(() => {
        fetch_form_fields({
            formKey: "product",
            id: id
        }).then((response) => {
            if (response.data !== undefined) {
                setFields(response.data);
                setModelInfo({
                    title: response.model_title,
                    save_url: response.api_url,
                    index_url: response.index_redirect_url
                })
                let newValueFields = {}
                response.data.forEach((field) => {
                    newValueFields[field.key] = field.value
                })

                newValueFields["template_id"] = template.id
                setFieldValues(newValueFields)
            }
        })
    }, [])

    return <div>
        <div>
            <div className='rounded-2 border box-shadow'>
                <div className="flex-container desktop-flex-row">
                    <div className="left-image pt-2 pb-2 mobile-img-container">
                        <img src={template.image} style={{ height: '100%', width: "100%" }} alt="Your Image" />
                    </div>
                    <div className="p-2" style={{ flexGrow: 1 }}>
                        <h3>{template.title}</h3>
                        <p>{template.description}</p>
                    </div>
                    <div className='p-2' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minWidth: 200 }}>
                        <button className='btn btn-secondary' onClick={() => {
                            clearTemplate()
                        }}>Clear & Reselect</button>
                    </div>
                </div>
            </div>

            <div className="form-renders">
                <div className="inputs-container bg-white mb-2 p-3">
                    <div className="product-info form row">
                        {fields.map((field, index) => (
                            <DynamicInput
                                key={index + "-input"}
                                fieldData={field}
                                fieldValue={fieldValues[field.key]}
                                handleChange={handleInputFieldChange}
                                handleAttachmentChange={handleInputFieldChange}
                                containerClass={field.type.includes("image") ? "col-12 mb-4" : "col-6 mb-4"}
                                errorMessage={errorState[field.key] !== undefined ? errorState[field.key] : ""}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
                    <button className={"btn btn-primary p-10-50"}
                        onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default ProductForm;

const processValidationErrors = (response, setErrorState) => {
    const isValidationErrorResponse = (response.status === "error" && response.type === "validation");
    if (!isValidationErrorResponse) {
        setErrorState({});
        return;
    }

    let fieldErrorMsgMap = {}
    Object.keys(response.validations).forEach((key) => {
        const messages = response.validations[key]
        if (messages.length > 0) {
            fieldErrorMsgMap[key] = messages[0]; // Take the first error message for the field
        }
    });

    setErrorState(fieldErrorMsgMap);
};
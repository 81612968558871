import React, {useEffect, useState} from "react";
import {notify_promise} from "../../services/utils/toasts";
import CardListing from "./CardListing";
import {fetch_stats, refresh_stats} from "../../services/shelters/stats";
import optionsIcon1 from "../../resources/themes/dashboard-v1/icons/3-dots-vertical.svg";

import StatsCard from "./StatsCard";
import BannerSwiper from "./common/BannerSwiper.tsx";
import {fetch_stats_new} from "../../services/stats.ts";

const DashboardMain = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    const [alerts, setAlerts] = useState([]);
    const [cards, setCards] = useState([]);
    const [orders, setOrders] = useState([]);
    const [adoptions, setAdoptions] = useState([]);
    const [users, setUsers] = useState([]);
    const [bookings, setBookings] = useState([]);

    const [toDate, setToDate] = useState(currentDate);
    const [fromDate, setFromDate] = useState(() => {
        const tenDaysBefore = new Date();
        tenDaysBefore.setDate(tenDaysBefore.getDate() - 10);
        return tenDaysBefore.toISOString().split("T")[0];
    });

    const getStatistics = () => {
        notify_promise(
            async () => {
                await fetch_stats({from: fromDate, to: toDate}).then((response) => {
                    let responseCards = [];
                    if (response.cards.latest !== undefined) {
                        responseCards = response.cards.latest;
                    }
                    setCards(responseCards);
                    if (response.orders.length > 0) {
                        setOrders(response.orders);
                    }
                    if (response.adoptions.length > 0) {
                        setAdoptions(response.adoptions);
                    }
                    if (response.users.length > 0) {
                        setUsers(response.users);
                    }
                    if (response.bookings.length > 0) {
                        setBookings(response.bookings);
                    }
                })

                await fetch_stats_new().then((response) => {
                    if (response.alerts) {
                        setAlerts(response.alerts);
                    }
                })
            }


        );
    };

    const handleRefresh = () => {
        notify_promise(refresh_stats(), "Stats updated, try refreshing in a while");
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    useEffect(() => {
        getStatistics();
    }, [fromDate, toDate]);

    return (
        <section className="page-content py-3 px-4 bg-lightgray">
            <h1 className="dashboard-title">Dashboard</h1>
            <div className={"mb-3"}>
                <BannerSwiper items={alerts}/>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="pe-0 d-flex justify-content-end gap-2">
                            <input
                                type="date"
                                className="form-control w-auto"
                                value={fromDate}
                                onChange={handleFromDateChange}
                            />
                            <p className="m-0"> _ </p>
                            <input
                                type="date"
                                className="form-control w-auto"
                                value={toDate}
                                onChange={handleToDateChange}
                            />
                            <button
                                className="btn btn-outline-secondary mb-2"
                                onClick={handleRefresh}
                            >
                                Refresh
                            </button>
                        </div>
                    </div>
                </div>
                {cards.length == 0 ? (
                    <div>
                        <p className={"text-center"}>No stats to show yet</p>
                    </div>
                ) : ""}
            </div>
            <div className="row dashboard-stats">
                <div className="col-md-8 col-sm-12">
                    <div className="row">
                        {cards.map((stat, index) => (
                            <div className="col-xxl-4 col-md-6 col-sm-12 mb-3" key={index}>
                                <StatsCard border={false} stat={stat} icon={optionsIcon1}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    {orders.length > 0 && (
                        <CardListing title="Last Orders" type="orders" entries={orders}/>
                    )}
                    {bookings.length > 0 && (
                        <CardListing
                            title="Last Appointments"
                            type="bookings"
                            entries={bookings}
                        />
                    )}
                    {users.length > 0 && (
                        <CardListing title="Last Joiners" type="users" entries={users}/>
                    )}
                    {adoptions.length > 0 && (
                        <CardListing
                            title="Last Adoption Orders"
                            type="adoptions"
                            entries={adoptions}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default DashboardMain;

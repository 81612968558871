const API_HOST = `${process.env.REACT_APP_DASHBOARD_API_URL}`;

const fetch_stats_new = () => {
    const url = `${API_HOST}/api/dash/stats/new`;

    return fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}

export {
    fetch_stats_new
}
// @ts-ignore
import React from 'react';

type BannerItem = {
    title: string;
    description: string;
    link?: string;
    button_text: string;
    background_color?: string; // Optional custom background color
    font_color?: string; // Optional custom font color
    button_background_color?: string; // Optional custom button bg color
    button_font_color?: string; // Optional custom button font color
    icon_url?: React.ReactNode; // Optional custom icon
};

type BannerSwiperProps = {
    items: BannerItem[];
};

const BannerSwiper: React.FC<BannerSwiperProps> = ({ items }) => {
    const defaultBackgroundColor = '#fd7d64';
    const defaultFontColor = '#ffffff';
    const defaultButtonBackgroundColor = '#ffffff';
    const defaultButtonFontColor = '#fd7d64';

    return (
        <div id="bannerSwiperComponent" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {items.map((item, index) => (
                    <div
                        className={`carousel-item ${index === 0 ? 'active' : ''}`}
                        key={index}
                    >
                        <div
                            className="p-3 rounded"
                            style={{
                                backgroundColor: item.background_color || defaultBackgroundColor,
                                color: item.font_color || defaultFontColor,
                                minHeight: 150
                            }}
                        >
                            {/* Title and Icon in a single row */}
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h3 className="fw-bold mb-0">{item.title}</h3>
                                <span
                                    style={{
                                        fontSize: '1.5rem',
                                        color: item.font_color || defaultFontColor,
                                    }}
                                >
                                    {item.icon_url ? <img height={30} src={item.icon_url} alt={item.title} /> : ""}
                                </span>
                            </div>

                            <div className="text-start">
                                <p>{item.description}</p>
                            </div>
                            {item.link ? (
                                <div className="text-end">
                                    <a
                                        href={item.link}
                                        className="btn"
                                        role="button"
                                        style={{
                                            backgroundColor: item.button_background_color || defaultButtonBackgroundColor,
                                            color: item.button_font_color || defaultButtonFontColor,
                                        }}
                                    >
                                        {item.button_text}
                                    </a>
                                </div>
                            ) : ""}
                        </div>
                    </div>
                ))}
            </div>

            {/* Dots Navigation */}
            <div className="carousel-indicators">
                {items.map((_, index) => (
                    <button
                        type="button"
                        key={index}
                        data-bs-target="#bannerSwiperComponent"
                        data-bs-slide-to={index}
                        className={index === 0 ? 'active' : ''}
                        aria-current={index === 0 ? 'true' : undefined}
                        aria-label={`Slide ${index + 1}`}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default BannerSwiper;
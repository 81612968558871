import React, { useEffect, useState } from 'react';
import { search_product_templates } from "../../../../../services/admin/products.ts"

type TemplateSearchProps = {
    onTemplateSelect: (template: any) => void;
};

const TemplateSearchComponent: React.FC<TemplateSearchProps> = ({ onTemplateSelect }) => {
    const [query, setQuery] = useState("")
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState({
        id: null
    })

    useEffect(() => {
        if (query.trim().length === 0) {
            return;
        }

        search_product_templates({
            query: query
        }).then((res) => {
            if (res.data) {
                setTemplates(res.data)
            }
        })
    }, [query])



    return <div>
        <div>
            <input type="text"
                placeholder='Product Name (e.g: Friskies)'
                onChange={(e) => {
                    setQuery(e.target.value)
                }}
                className="w-100 no-print form-control list-search ps-3 py-2 rounded-2"
            />
        </div>
        <div className='w-100'>
            <div className='p-2'>
                <p className='text-grey'>Displaying results for "{query}"</p>
            </div>

            <div className='row'>
                {templates.map(template => {
                    return <div key={template.id} className='col-12 mb-2'>
                        <div className='rounded-2 border box-shadow'>
                            <div className="flex-container desktop-flex-row">
                                <div className="left-image pt-2 pb-2 mobile-img-container">
                                    <img src={template.image} style={{ height: '100%', width: "100%" }} alt="Your Image" />
                                </div>
                                <div className="p-2" style={{ flexGrow: 1 }}>
                                    <h3>
                                        <HighlightedText text={template.title} highlight={query} />
                                    </h3>
                                    <p>
                                        <HighlightedText text={template.description} highlight={query} />
                                    </p>
                                </div>
                                <div className='p-2' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <button
                                        className='btn btn-primary'
                                        onClick={() => {
                                            onTemplateSelect(template)
                                        }}
                                    >
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default TemplateSearchComponent;



const HighlightedText = ({ text, highlight }) => {
    // Split the text into parts based on the highlighted word
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));

    return (
        <>
            {parts.map((part, index) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <span key={index} style={{ backgroundColor: "#fed4cb" }}>
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
        </>
    );
};

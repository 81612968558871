export const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/product-templates";
type FetchProductTemplateOptions = any

export const fetch_product_templates = async (options : FetchProductTemplateOptions) => {
    const encodedQueryParams = new URLSearchParams(options);

    const reqInit : RequestInit = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }

    return fetch(`${API_HOST}/?${encodedQueryParams}`, reqInit).then((res) => res.json());
};

type SearchProductTemplateOptions = {
    query: string
}

export const search_product_templates = async (options : SearchProductTemplateOptions) => {
    const encodedQueryParams = new URLSearchParams(options);

    const reqInit : RequestInit = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }

    return fetch(`${API_HOST}/search?${encodedQueryParams}`, reqInit).then((res) => res.json());
};

type DeleteProductTemplateOptions = string[]

export const delete_product_templates = async (ids : DeleteProductTemplateOptions) => {
    let idCsv = ids.join(",");
    const reqInit: RequestInit = {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return fetch(`${API_HOST}/${idCsv}`, reqInit).then((res) => res.json());
};


export const export_product_templates = async () => {
    const reqInit: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }
    const res = await fetch(`${API_HOST}/export`, reqInit);
    return await res.json();
};